document.addEventListener("DOMContentLoaded", () => {
  document.querySelector("#mobile-nav-button-open").addEventListener("click", () => {
    document.getElementById("mobile-nav-button-open").setAttribute("aria-expanded", true);
    document.getElementById("mobile-nav-button-close").setAttribute("aria-expanded", true);
    document.querySelector("#mobile-nav").classList.toggle("hidden");
  })
  document.querySelector("#mobile-nav-button-close").addEventListener("click", () => {
    document.getElementById("mobile-nav-button-open").setAttribute("aria-expanded", false);
    document.getElementById("mobile-nav-button-close").setAttribute("aria-expanded", false);
    document.querySelector("#mobile-nav").classList.toggle("hidden");
  })
})
