document.addEventListener("DOMContentLoaded", () => {
  const mobileFilterButtonOpen = document.querySelector("#mobile-filter-button-open");
  const mobileFilterButtonClose = document.querySelector("#mobile-filter-button-close");

  if ( mobileFilterButtonOpen ){
    mobileFilterButtonOpen.addEventListener("click", () => {
      document.getElementById("mobile-filter-button-open").setAttribute("aria-expanded", true);
      document.getElementById("mobile-filter-button-close").setAttribute("aria-expanded", true);
      document.querySelector("#mobile-filter").classList.toggle("hidden");
    })
  }
  if ( mobileFilterButtonClose ){
    mobileFilterButtonClose.addEventListener("click", () => {
      document.getElementById("mobile-filter-button-open").setAttribute("aria-expanded", false);
      document.getElementById("mobile-filter-button-close").setAttribute("aria-expanded", false);
      document.querySelector("#mobile-filter").classList.toggle("hidden");
    })
  }

  const filterCatButtons = document.querySelectorAll(".material-category-filter-button");
  const filterTopButtons = document.querySelectorAll(".material-topic-filter-button");
  const resetFiltersButtons = document.querySelectorAll(".reset-filters-button");
  const materialEntries = document.querySelectorAll(".material-entry")

  let activeCats = [];
  let activeTops = [];

  filterCatButtons.forEach((filterCatButton) => {
    filterCatButton.addEventListener('click', () => {
      filterCatButton.classList.toggle('active');
      if( activeCats.includes(filterCatButton.dataset.cat) ){
        activeCats = activeCats.filter( i => i !== filterCatButton.dataset.cat );
      }else{
        activeCats.push(filterCatButton.dataset.cat);
      }
      filterMat();
    })
  })
  filterTopButtons.forEach((filterTopButton) => {
    filterTopButton.addEventListener('click', () => {
      filterTopButton.classList.toggle('active');
      if( activeTops.includes(filterTopButton.dataset.topic) ){
        activeTops = activeTops.filter( i => i !== filterTopButton.dataset.topic );
      }else{
        activeTops.push(filterTopButton.dataset.topic);
      }
      filterMat();
    })
  })
  resetFiltersButtons.forEach((resetFiltersButton) => {
    resetFiltersButton.addEventListener("click", () => {
      activeCats = [];
      activeTops = [];
      deactivateFilterBtn();
      filterMat();
    })
  })

  const deactivateFilterBtn = () => {
    document.querySelectorAll(".material-category-filter-button.active").forEach((btn) => {
      btn.classList.toggle('active');
    })
    document.querySelectorAll(".material-topic-filter-button.active").forEach((btn) => {
      btn.classList.toggle('active');
    })
  }
  const filterMat = () => {
    // Filter all MatEntries by Cat
    if ( activeCats.length > 0 ){
      materialEntries.forEach((materialEntry) =>{
        if( activeCats.includes(materialEntry.dataset.cat) ){
          materialEntry.classList.remove('d-none');
        }else{
          materialEntry.classList.add('d-none');
        }
      })
    } else {
      materialEntries.forEach((materialEntry) => materialEntry.classList.remove('d-none') );
    }
    // Filter all visible MatEntries by Tops
    if ( activeTops.length > 0 ){
      let activematerialEntries = document.querySelectorAll(".material-entry:not(.d-none)");
      activematerialEntries.forEach((activeMatEntry) => {
        let commonTops  = activeMatEntry.dataset.topics.split(',').filter( activeMatEntryTopic => activeTops.includes(activeMatEntryTopic) );
        if( commonTops.length === 0 ){
          activeMatEntry.classList.add('d-none');
        }
      })
    }
  }
});
