// To see this message, follow the instructions for your Ruby framework.
//
// When using a plain API, perhaps it's better to generate an HTML entrypoint
// and link to the scripts and stylesheets, and let Vite transform it.
console.log('Vite ⚡️ Ruby')

// import all from bootstrap. we could also load specific modules instead
import * as bootstrap from 'bootstrap'
import lazySizes from 'lazysizes'
// Example: Import a stylesheet in <sourceCodeDir>/index.css
import '~/scss/application.scss'
import '~/js/application.js'
